@import "ngx-bootstrap/datepicker/bs-datepicker.css";

.bs-datepicker {
  // background-color: transparent;
  @include box-shadow(none);

  .bs-datepicker-container {
    padding: 0;
  }

  .bs-datepicker-body {
    @include border-bottom-radius($border-radius);
  }

  .bs-datepicker-multiple {
    background-color: #fff;
  }
}

bs-daterangepicker-inline-container {
  &,
  .bs-datepicker-container,
  bs-days-calendar-view,
  bs-years-calendar-view,
  bs-month-calendar-view {
    width: 100%;
  }
}
