// stylelint-disable declaration-no-important, selector-max-class

@import "@city-tax/styles/web/functions";
@import "@city-tax/styles/web/variables";
@import "@city-tax/styles/web/mixins";
@import "@ng-select/ng-select/themes/default.theme.css";

.ng-select {
  .ng-select-container {
    min-height: $input-height;
    background-color: $white;
    background-clip: padding-box;
    border: $border-width solid $border-color;
    border-radius: $border-radius;

    .ng-value-container {
      padding-left: $input-btn-padding-x;

      .ng-placeholder {
        color: $input-placeholder-color;
        padding-left: 2px !important;
      }
    }

    &.ng-has-value {
      > .ng-clear-wrapper {
        display: inline-block;
        width: ($font-size-base * $input-btn-line-height);
        height: ($font-size-base * $input-btn-line-height);
        font-style: normal;
        vertical-align: top;
        fill: currentColor;
        stroke: inherit;

        > span {
          display: block;
          width: inherit;
          height: inherit;
          font-size: 0;
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3e%3cpath fill='%23939393' d='M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z' /%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-size: ($font-size-base * $input-btn-line-height) ($font-size-base * $input-btn-line-height);
        }
      }
    }

    @include hover-focus {
      @include box-shadow(none);
    }
  }

  &.ng-select-focused:not(.ng-select-opened) {
    > .ng-select-container {
      border-color: $input-btn-focus-color;
      @include box-shadow(none);
    }
  }

  &.ng-select-single {
    .ng-select-container {
      height: auto;

      .ng-value-container {
        .ng-input {
          top: 8px;
          padding-left: $input-btn-padding-x;
        }
      }
    }
  }

  &.ng-select-multiple {
    .ng-placeholder {
      top: 10px !important;
      // padding-left: 1rem;
    }

    .ng-input {
      padding-bottom: 7px !important;
    }

    .ng-value {
      color: color-yiq(theme-color-level("primary", 0));
      background-color: theme-color-level("primary", 0) !important;
      border: $border-width solid theme-color-level("primary", 1) !important;
      border-radius: $border-radius !important;

      .ng-value-icon.left {
        border-right: $border-width solid theme-color-level("primary", 1) !important;
      }

      .ng-value-icon:hover {
        background-color: theme-color-level("primary", 1) !important;
      }
    }

    .ng-select-container {
      .ng-value-container {
        padding-left: $input-btn-padding-x;

        .ng-placeholder,
        .ng-input {
          padding-left: 0;
        }
      }
    }
  }

  &.ng-select-disabled > .ng-select-container {
    background-color: $input-disabled-bg;
  }

  &.ng-select-opened > .ng-select-container {
    border-color: $input-btn-focus-color;
  }

  .ng-arrow-wrapper {
    padding-right: $input-btn-padding-x;

    > .ng-arrow {
      top: 1px;
    }
  }

  &.is-invalid > .ng-select-container {
    border-color: theme-color("danger");
  }
}

.ng-dropdown-panel {
  &.ng-select-bottom {
    border-color: $input-btn-focus-color;
    border-top-color: $border-color;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  &.ng-select-top {
    border-color: $input-btn-focus-color;
    border-bottom-color: $border-color;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .ng-dropdown-panel-items {
    .ng-option {
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      min-height: $input-height;
      padding-right: $input-btn-padding-x !important;
      padding-left: $input-btn-padding-x !important;
    }

    .ng-optgroup {
      padding: map-get($map: $spacers, $key: 2) $input-btn-padding-x !important;
      background-color: theme-color-level("primary", -12);
      min-height: $input-height;

      ~ .ng-option {
        padding-left: ($input-btn-padding-x * 2) !important;
      }
    }
  }
}
