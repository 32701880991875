body {
  font-family: "Poppins", sans-serif;
  background-color: #f2f2f2;
}

.language-area a,
.language-area a.active-lang {
  font-family: "Poppins", Sans-serif !important;
  font-size: 14px !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Poppins", Sans-serif !important;
}


.btn-outline-secondary {
  border-color: #fff;
  color: #fff;
}

.footer-section {
  background-color: #0069a7;
}

.btn-success {
  background-color: #79c04e;
  border-color: #79c04e;
}

.btn-success:hover {
  color: #fff;
  background-color: #54595b;
  border-color: #54595b;
}

.text-blue {
  color: #202b5d !important;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #54595b;
  border-color: #54595b;
}

.form-area-section .content .row {
  margin: 0;
}

.form-area-section .content .row .card {
  box-shadow: none;
}

.n-shadow {
  box-shadow: 0px 0px 33px 0px rgb(0 0 0 / 11%);
}

.top-shadow {
  box-shadow: 0 3px 16px 0 rgb(37 50 123 / 20%);
}

.form-area-section .content .bg-gray-100 {
  background-color: #f2f2f2 !important;
}

.form-area-section .content .row .card {
  box-shadow: 0 0 33px #0000001c;
  border: none;
}

.logo-alt {
  background-image: url(assets/logo/logo.png);
  height: 90px;
  width: 167px;
}

.w-150 {
  width: 90px;
}

.btn-primary {
  background-color: #0069a7 !important;
  box-shadow: 0 4px 9px 1px rgb(37 50 123 / 30%) !important;
  border-color: #0069a7;
  border-radius: 0 !important;
}

.text-primary {
  color: #0069a7 !important;
}

.bg-primary {
  background-color: #0069a7 !important;
}


admin-dashboard admin-dashboard-sidebar:before {
  background-color: #1a1a2b !important;
  box-shadow: 4px 0px 14px 0px rgb(0 0 0 / 10%);
}

admin-dashboard admin-dashboard-sidebar .nav .nav-link.active {
  background: linear-gradient(
                  90deg,
                  #1179cc 0%,
                  rgba(255, 255, 255, 0) 100%
  ) !important;
}

admin-dashboard admin-dashboard-sidebar .nav .nav-link.active:after {
  background-color: #05233b !important;
}

@media (min-width: 1280px) {
  .form-area-section .content .row .card {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .form-area-section .content .row .card {
    max-width: 960px;
  }
  body .otp-section .row .card {
    max-width: 680px !important;
    min-width: 680px !important;
  }
}

.otp-sign-in a {
  color: #fff;
  background-color: #1179cc;
  border-color: #1179cc;
  padding: 0.823rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  transition: 0.15s ease-in-out;
}

.otp-sign-in a:hover,
.otp-sign-in a:focus {
  background-color: #0a487a;
  border-color: #0a487a;
}

.set-center {
  max-width: 100%;
  flex: 0 0 100%;
  text-align: center;
}

.logo-img-side {
  max-height: 50px;
  margin-right: 15px;
  margin-left: 10px;
  max-width: 200px;
}
