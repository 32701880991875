// stylelint-disable declaration-no-important, selector-max-type, selector-max-class, selector-max-compound-selectors, selector-max-combinators, selector-no-qualifying-type

@import "@city-tax/styles/web/functions";
@import "@city-tax/styles/web/variables";
@import "@city-tax/styles/web/mixins";
@import "@swimlane/ngx-datatable/index.css";
@import "@swimlane/ngx-datatable/themes/material.css";
@import "@swimlane/ngx-datatable/assets/icons.css";

.ngx-datatable.bootstrap {
  display: flex;
  flex: 1;
  flex-direction: column;
  // justify-content: flex-end;
  overflow: visible;

  > .visible {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .datatable-header {
    // border-bottom: 1px solid $border-color;

    .datatable-header-inner {
      height: inherit;
    }

    .datatable-header-cell {
      display: flex;
      font-weight: 500;
      align-items: center;
      padding: 0.5rem;
      text-transform: uppercase !important;
      font-size: 85%;
      vertical-align: middle;
      justify-content: flex-start !important;

      &:first-child {
        padding-left: map-get($map: $spacers, $key: 3);
      }

      &:last-child {
        padding-right: map-get($map: $spacers, $key: 3);
      }

      .datatable-header-cell-template-wrap {
        display: flex;
        align-items: center;
      }

      //&.sortable .sort-btn {
      //  display: block;
      //  width: $custom-control-indicator-size;
      //  height: $custom-control-indicator-size;
      //  margin-left: 5px;
      //  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3e%3cpath fill='%237689a1' d='M12,6L7,11H17L12,6M7,13L12,18L17,13H7Z' /%3e%3c/svg%3e");
      //  background-repeat: no-repeat;
      //  background-position: center center;
      //  background-size: 98% 98%;
      //
      //  &.datatable-icon-up {
      //    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3e%3cpath fill='%237689a1' d='M7,15L12,10L17,15H7Z' /%3e%3c/svg%3e");
      //  }
      //
      //  &.datatable-icon-down {
      //    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3e%3cpath fill='%237689a1' d='M7,10L12,15L17,10H7Z' /%3e%3c/svg%3e");
      //  }
      //}
    }

    .datatable-header-inner .datatable-row-center {
      transform: unset !important;
      position: unset !important;
    }

    .datatable-header-cell .setting-data-dropdown {
      top: 50px !important;
    }

    .datatable-header-cell.position-unset,
    .position-unset {
      position: unset !important;
    }

    .datatable-header-cell.position-unset {
      // flex-basis: 0;
      // flex-grow: 1;
      // max-width: 100%;
    }

    .datatable-header-cell {
      font-weight: 500 !important;
      padding: 0.5rem !important;
      //text-transform: capitalize !important; font-size: 12px !important;
      font-size: 12px !important;

    }


    .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active, .btn:focus, .btn.focus {
      box-shadow: none !important;
    }

  }

  .datatable-body {
    flex-grow: 1;

    .datatable-row-wrapper {
      //width: 100% !important;
      margin-bottom: map-get($map: $spacers, $key: 2);
      background-color: $white;
      border: $border-width solid $border-color;
      @include border-radius($border-radius);

      /* &:first-child {
        .datatable-body-row {
          border-top-width: 0;
        }
      } */
    }

    .datatable-body-row {
      display: block !important;
      //width: auto !important;

      .datatable-row-group {
        width: 100% !important;
      }

      .datatable-body-cell {
        display: flex;
        align-items: center;
        padding: 0.5rem;
        overflow: visible !important;

        &:first-child {
          padding-left: map-get($map: $spacers, $key: 3);
        }

        &:last-child {
          padding-right: map-get($map: $spacers, $key: 3);
        }

        &:not(.text-truncate-none) .datatable-body-cell-label {
          @include text-truncate();
          width: 100%;
        }

        &.text-truncate-none .datatable-body-cell-label {
          white-space: normal;
        }
      }

      &.active {
        background-color: theme-color-level("primary", -12);
      }

      &.datatable-row-even:not(.active) {
        background-color: rgba(theme-color-level("primary", -12), 0.4);

        + .datatable-row-detail {
          background-color: rgba(theme-color-level("primary", -12), 0.4);
        }
      }

      @include hover-focus {
        // cursor: pointer;
        // background-color: theme-color-level("primary", -11);
      }
    }

    .datatable-scroll {
      //width: 100% !important;
      vertical-align: top;
    }

    .empty-row {
      position: relative;
      padding: map-get($map: $spacers, $key: 2) map-get($map: $spacers, $key: 3);
    }

    .datatable-row-detail {
      height: auto !important;
    }

    .progress-linear {
      position: absolute;
      display: block;
      width: 100%;
      height: 2px;
      padding: 0;
      margin: 0;

      .container {
        position: relative;
        display: block;
        width: 100%;
        max-width: 100%;
        height: inherit;
        margin: 0;
        overflow: hidden;
        background-color: theme-color-level("primary", -10);
        background-clip: padding-box;

        .bar {
          background-color: theme-color-level("primary", 0);

          &::before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            content: "";
            background-color: inherit;
            will-change: left, right;
            animation: indeterminate 2s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
          }

          &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            content: "";
            background-color: inherit;
            will-change: left, right;
            animation: indeterminate-short 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation-delay: 1.35s;
          }
        }
      }
    }
  }

  .datatable-footer {
    background-color: theme-color-level("gray", -12);
    // border-top: 1px solid lighten($color: $border-color, $amount: 40);

    .datatable-footer-inner {
      display: flex;
      align-items: center;
      height: unset !important;
      padding: map-get($map: $spacers, $key: 2) map-get($map: $spacers, $key: 3);

      .datatable-pager {
        .pager {
          > li {
            > a {
              display: block;
              min-width: 30px;
              padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
              color: $body-color;
              text-align: center;
              background-color: theme-color-level("gray", -12);
              border-radius: $btn-border-radius-sm;

              [class^="datatable-icon-"]::before,
              [class*=" datatable-icon-"]::before {
                display: inline-block;
                vertical-align: -3px;
              }
            }

            + li {
              margin-left: 3px;
            }

            &.disabled > a {
              color: $text-muted;
            }

            &.active > a {
              font-weight: $font-weight-medium;
              color: theme-color("primary");
            }
          }
        }

        .datatable-icon-prev,
        .datatable-icon-skip,
        .datatable-icon-left,
        .datatable-icon-right {
          display: inline-block;
          width: $custom-control-indicator-size;
          height: $custom-control-indicator-size;
          vertical-align: middle;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 98% 98%;
        }

        .datatable-icon-prev {
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpath fill='%237689a1' d='M6,18V6H8V18H6M9.5,12L18,6V18L9.5,12Z' /%3e%3c/svg%3e");
        }

        .datatable-icon-left {
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpath fill='%237689a1' d='M14,7L9,12L14,17V7Z' /%3e%3c/svg%3e");
          background-size: 100% 100%;
        }

        .datatable-icon-skip {
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpath fill='%237689a1' d='M16,18H18V6H16M6,18L14.5,12L6,6V18Z' /%3e%3c/svg%3e");
        }

        .datatable-icon-right {
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpath fill='%237689a1' d='M10,17L15,12L10,7V17Z' /%3e%3c/svg%3e");
          background-size: 100% 100%;
        }
      }
    }
  }
}

.ngx-datatable.datatable-flush {
  .datatable-body {
    flex-grow: 1;

    .datatable-row-wrapper {
      margin-bottom: 0;
      background-color: $white;
      border: none;
      @include border-radius(0);
    }

    .datatable-body-row {
      border-top: 1px solid $border-color;
    }
  }

  .datatable-header {
    // background-color: rgba(0, 0, 0, .02);
    border-bottom: 1px solid $border-color;
  }

  .datatable-footer {
    border-top: 1px solid lighten($color: $border-color, $amount: 40);
  }
}

.ngx-datatable.scroll-horz .datatable-body {
  overflow-y: hidden;
}

.ngx-datatable.datatable-no-header {
  > .visible {
    > .datatable-header {
      display: none !important;
    }

    .datatable-row-wrapper {
      &:first-child .datatable-body-row {
        border-top-width: 0;
      }
    }
  }
}

@keyframes indeterminate {
  0% {
    right: 100%;
    left: -35%;
  }
  60% {
    right: -90%;
    left: 100%;
  }
  100% {
    right: -90%;
    left: 100%;
  }
}

@keyframes indeterminate-short {
  0% {
    right: 100%;
    left: -200%;
  }
  60% {
    right: -8%;
    left: 107%;
  }
  100% {
    right: -8%;
    left: 107%;
  }
}

[data-icon]::before {
  font-family: 'data-table' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='datatable-icon-']::before,
[class*=' datatable-icon-']::before {
  font-family: 'data-table' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.datatable-icon-collapse::before {
  content: '\61';
}

.datatable-icon-expand::before {
  content: '\63';
}

.datatable-icon-close::before {
  content: '\64';
}


