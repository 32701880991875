// stylelint-disable selector-type-no-unknown, selector-max-type, selector-no-qualifying-type

timepicker {
  > table {
    td {
      padding: 0;

      &.form-group {
        vertical-align: top;
      }
    }
  }
}
