.timeline {
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.timeline-item {
  position: relative;
  padding-left: 0;
  list-style: none;
  // margin-top: map-get($map: $spacers, $key: 3);
  // padding: map-get($map: $spacers, $key: 2) 0;
}

.timeline-bullet {
  position: relative;

  > span {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 17px;
    height: 17px;
    font-size: 75%;
    font-weight: 500;
    color: $white;
    border-radius: 50%;
    box-shadow: 0 0 0 2px $white;
    background-color: theme-color-level("gray", -5);
    overflow: hidden;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    width: 1px;
    height: 999px;
    margin-left: -1px;
    content: "";
    background-color: theme-color-level("gray", -11);
  }
}

.gu-mirror .timeline-bullet {
  &::after {
    display: none;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($danger, 0.6);
  }
  70% {
    box-shadow: 0 0 0 10px rgba($danger, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($danger, 0);
  }
}
