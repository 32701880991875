// stylelint-disable selector-type-no-unknown, declaration-no-important, selector-no-qualifying-type
@import "@city-tax/styles/web/functions";
@import "@city-tax/styles/web/variables";
@import "@city-tax/styles/web/mixins";

ngx-dnd-container {
  > .ngx-dnd-container {
    padding: 0;
    margin: 0;
    background-color: transparent !important;
    border-width: 0;

    &.gu-empty {
      min-height: $input-height;
      border: $border-width dashed $border-color !important;
    }
  }
}

.ngx-dnd-item {
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  border-width: 0 !important;

  + .ngx-dnd-item {
    margin-top: 15px !important;
  }

  &.gu-mirror {
    background-color: #fff !important;
    box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.05),
      0 10px 14px 1px rgba(0, 0, 0, 0.05), 0 4px 18px 3px rgba(0, 0, 0, 0.05);
    opacity: 1 !important;
    transform: rotate(1deg);
  }

  &.gu-transit {
    opacity: 0.5 !important;
  }
}

.gu-mirror {
  position: fixed !important;
  z-index: 9999 !important;
  margin: 0 !important;
  pointer-events: none;
  opacity: 0.8;
}
.gu-hide {
  left: -9999px !important;
}
.gu-unselectable {
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
}
