@import "bootstrap-scss/grid";

// Row
//
// Rows contain and clear the floats of your columns.

@if $enable-grid-classes {
  .row {
    margin-right: ($grid-gutter-width / -4);
    margin-left: ($grid-gutter-width / -4);

    > .col,
    > [class*="col-"] {
      padding-right: ($grid-gutter-width / 4);
      padding-left: ($grid-gutter-width / 4);
    }

    &.no-gutters {
      margin-right: 0;
      margin-left: 0;

      > .col,
      > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}
