@import "flatpickr/dist/flatpickr.min.css";

@import "@city-tax/styles/web/functions";
@import "@city-tax/styles/web/variables";
@import "@city-tax/styles/web/mixins";


.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: theme-color-level("primary") !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff !important;
  border-color: theme-color-level("primary") !important;
}

