@import "bootstrap-scss/modal";

// Shell div to position the modal with bottom padding
.modal-dialog.fade-left {
  .modal.fade & {
    @include transition($modal-transition);
    transform: translate(25%, 0);
  }

  .modal.show & {
    transform: translate(0, 0);
  }
}

.modal-body {
  display: flex;
  flex-direction: column;
}

.modal-header {
  position: relative;
  z-index: 1;
  border-bottom: $border-width solid $border-color;
  @include box-shadow($box-shadow);
}

// Footer (for actions)
.modal-footer {
  background-color: lighten($color: theme-color-level("gray", -12), $amount: 1);
  @include border-bottom-radius($modal-content-border-radius);
}

// Modal full height
.modal-dialog.modal-dialog-tall {
  display: flex;
  flex-direction: column;
  height: calc(100% - #{$modal-dialog-margin * 2});

  @include media-breakpoint-up(sm) {
    height: calc(100% - #{$modal-dialog-margin-y-sm-up * 2});
  }

  .modal-content {
    flex: 1;
    @include border-right-radius(0);
  }
}

// Modal right position
.modal-dialog.modal-dialog-right {
  margin-right: 0;

  &.modal-dialog-tall {
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }
}

// Scale up the modal
@include media-breakpoint-up(lg) {
  .modal-xlg {
    max-width: $modal-xlg;
  }
  .modal-xxl {
    max-width: $modal-xxl;
  }
}

.modal-phone {
  max-width: 427px;

  > .modal-content {
    background-color: transparent;
    box-shadow: none;
  }
}
