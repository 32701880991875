/* ===== Theme Colors Can be Override From these Variables ===== */

// $primary-color: #1e90ff; 
// $secondary-color: #2c3e50; 
// $success-color:  #2ed573; 
// $info-color:  #6c5ce7; 
// $warning-color:  #eccc68; 
// $danger-color:  #ff4757; 
// $gray-color: #2c3e50; 
// $pink-color:  #ff6b81; 
// $indigo-color:  #9452a5; 
// $orange-color:  #ffa502; 
// $teal-color:  #00d8d6; 
// $cyan-color:  #26de81; 