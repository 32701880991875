.logo {
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
}

.logo-alt {
  width: 220px;
  height: 67px;
  background-image: url("../../../apps/insource/src/assets/logo/logo.png");
  background-position: center;

}

.logo-text {
  width: 194px;
  height: 59px;
  background-image: url("../../../apps/insource/src/assets/logo/logo-text-white.png");
}

.logo-figure {
  width: 90px;
  height: 90px;
  background-image: url("../../../apps/insource/src/assets/logo/logo-figure-color.png");
}

.logo-lg {
  &.logo-alt {
    width: 320px;
    height: 97px;
  }
}

.logo-sm {
  &.logo-text {
    width: 118px;
    height: 36px;
  }

  &.logo-figure {
    width: 40px;
    height: 40px;
  }
}
