// stylelint-disable selector-type-no-unknown

@import "bootstrap-scss/reboot";

html {
  width: 100%;
  height: 100%;
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
