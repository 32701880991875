.scrollable {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.scrollable-main {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

  .scrollable-y & {
    overflow-y: auto;
  }
  .scrollable-x & {
    overflow-x: auto;
  }
  .scrollable-xy & {
    overflow: auto;
  }
}

.scrollable-content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}
