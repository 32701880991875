// stylelint-disable selector-max-class

@import "bootstrap-scss/nav";

.nav-tabs-line .nav-link {
  position: relative;
  padding-top: map-get($map: $spacers, $key: 3);
  padding-bottom: map-get($map: $spacers, $key: 3);
  color: inherit;
  border-width: 0;
  border-radius: 0;
  opacity: 0.6;

  @include hover-focus {
    opacity: 0.9;
  }

  &.active {
    color: theme-color("primary");
    background-color: transparent;
    opacity: 1;

    &::before {
      position: absolute;
      bottom: 1px;
      left: 0;
      width: 100%;
      height: 2px;
      content: "";
      background-color: theme-color("primary");
    }
  }
}

.nav-tabs-scrollable {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  .nav-link {
    white-space: nowrap;
  }
}

.tab-container {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;

  > .nav-tabs-content-scrollable {
    flex-grow: 0;

    ~ .tab-content {
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: column;

      > .tab-pane.active {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
      }
    }

    &.sss {
      ~ .tab-content {
        > .tab-pane.active {
          overflow: hidden;
        }
      }
    }
  }
}
