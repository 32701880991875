@import "bootstrap-scss/dropdown";

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
  &.disabled,
  &:disabled {
    opacity: 0.4;
  }
}

.dropdown-toggle-no-arrow::after {
  display: none;
}

.dropdown-toggle::after {
  position: relative;
  top: 1px;
  margin-left: 5px !important;
}
