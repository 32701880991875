// stylelint-disable declaration-no-important

@import "bootstrap-scss/type";

// Global letter spacing setting
* {
  letter-spacing: 0.15px !important;
}

// Type display classes
.display-5 {
  font-size: $display5-size;
  font-weight: $display5-weight;
  line-height: $display-line-height;
}
.display-6 {
  font-size: $display6-size;
  font-weight: $display6-weight;
  line-height: $display-line-height;
}
