@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

html {
  font-size: 14px;
}

* {
  letter-spacing: 0 !important;
}

body {
  font-family: "Poppins", sans-serif;
}

.font-weight-600 {
  font-weight: 600;
}

.return-filed {
  width: 100%;
  font-size: 130px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba($color: rgb(202, 201, 201), $alpha: .5);
  cursor: not-allowed;
  transform: rotate(-45deg);
  background: none;
  box-shadow: none;
  border: none;
}

.bg-filed {
  background-color: rgba($color: rgb(220, 220, 220), $alpha: .5);
}

.custom-control-input:checked ~ .custom-control-label:before {
  // background: url(img/cross.svg) no-repeat #1e90ff;
}

.payment-checks .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:after {
  background-image: none;
}

.payment-checks .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:before {
  border-color: rgba(0, 0, 0, .08);
  background-color: #f8f8f8;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-pills li.activeTabs {
  background-color: #1e90ff;
  border-radius: 0.2rem;
}

.nav-pills li.activeTabs a {
  color: #fff;
}

.nav-pills li a {
  cursor: pointer;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.cursor-pointer {
  cursor: pointer;
}

.highlight {
  background-color: aqua;
}

.bg-light {
  background: #f5f5f5;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.active-lang {
  color: rgb(20, 137, 240) ;
}

.w-50px {
  width: 50px;
}

.w-min-100px {
  min-width: 100px;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.f-bold {
  font-weight: bold;
}

// taxpayer-form css
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.taxpayer-form {
  font-size: 12px;
  font-family: arial;
}

.taxpayer-form input {
  padding: 0.375rem 0.5rem;
  height: auto;
  line-height: 1.2;
}

.taxpayer-form td {
  padding: 0.5rem;
}

.activeTabs {
  color: blue;
}

tr.payments-row input {
  width: 135px;
}

@media (max-width: 1600px) {
  .br-lg-none {
    display: none;
  }
}


.details-table .datatable-header-cell-label {
  font-weight: 500 !important;
}

svg.edit-icon {
  width: 13px;
  height: 13px;
}


// .datatable-inner-part .datatable-body-cell-label{
//   margin-left: 13px!important;
// }

// .datatable-inner-part .datatable-header-cell {
//   padding: 5px 20px!important;
// }

.details-table .datatable-scroll {
  width: 100% !important;
}

.datatable-inner-part .datatable-body-cell {
  min-height: 30px !important;
  border-right: 1px solid #eff2f5;
}

.details-table .datatable-header {
  border-bottom: 0px !important;
}

.details-table .datatable-footer {
  border-top: 0px !important;
}

.details-table, .details-table .datatable-body-row {
  border-color: #eff2f5 !important;
}

.details-table .datatable-row-wrapper:nth-of-type(even),
.details-table .datatable-row-wrapper:nth-of-type(even) .datatable-row-center {
  background-color: #ffffff !important;
  width: 100% !important;
}

.custom-checkbox {
  min-height: 0px;
}

.datatable-inner-part {
  padding-left: 4% !important;
  padding-right: 4% !important;
}

@media (min-width: 1400px) {
  .datatable-inner-part {
    padding-left: 6% !important;
    padding-right: 6% !important;
  }
}

@media (min-width: 2100px) {
  .datatable-inner-part {
    padding-left: 8% !important;
    padding-right: 8% !important;
  }
}

.datatable-inner-part .datatable-flush {
  border: 1px solid #eff2f5;
  height: 75px;
}

.datatable-inner-part .datatable-header .datatable-header-cell {
  background: #fff;
  padding: 21px 8px !important;
  border-right: 1px solid #eff2f5;
}

.datatable-inner-part .datatable-body-cell:last-child {
  border-right: 0px !important;
}

.datatable-inner-part .datatable-header {
  height: 40px !important;
}

// sidebar layout css

@media (min-width: 1200px) {
  .sidebar-toggle {
    display: none;
  }
}

@media (max-width: 1200px) {
  .sidebar.sidebar-collapse {
    display: none;
  }
  .sidebar.sidebar-collapse ~ admin-dashboard-main,
  .sidebar.sidebar-collapse ~ admin-dashboard-main admin-dashboard-header {
    margin-left: 0rem !important;
  }
  .sidebar.sidebar-collapse ~ admin-dashboard-main admin-dashboard-header {
    left: 0;
  }
}
