// stylelint-disable declaration-no-important, selector-max-class

@import "@city-tax/styles/web/functions";
@import "@city-tax/styles/web/variables";
@import "@city-tax/styles/web/mixins";

.pac-container {
  z-index: $zindex-popover;
  min-width: $dropdown-min-width;
  padding-top: $dropdown-padding-y;
  margin: $dropdown-spacer 0 0;
  font-family: inherit;
  font-size: $font-size-base;
  color: $body-color;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: $dropdown-border-width solid $dropdown-border-color;
  @include border-radius($dropdown-border-radius);
  @include box-shadow($dropdown-box-shadow);

  .pac-item {
    display: flex;
    align-items: center;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    font-size: inherit !important;
    line-height: inherit !important;
    color: $dropdown-link-color;
    border: 0;

    @include hover-focus {
      color: $dropdown-link-hover-color;
      text-decoration: none;
      @include gradient-bg($dropdown-link-hover-bg);
    }

    &.active,
    &:active {
      color: $dropdown-link-active-color;
      text-decoration: none;
      @include gradient-bg($dropdown-link-active-bg);
    }

    &.disabled,
    &:disabled {
      color: $dropdown-link-disabled-color;
      background-color: transparent;
      // Remove CSS gradients if they're enabled
      @if $enable-gradients {
        background-image: none;
      }
    }

    > span:last-child {
      opacity: 0.5;
      // font-size: $small-font-size;
    }
  }

  .pac-item-query {
    font-size: inherit !important;
  }

  .pac-icon-marker {
    flex-shrink: 0;
    margin-top: 0 !important;
  }
}

.pac-logo::after {
  padding: $spacer;
  background-color: theme-color-level("gray", -12);
  background-position: $spacer;
}
