@import "bootstrap-scss/carousel";

//
// Left/right controls for nav
//
.carousel-control {
  top: 50%;
  padding: 8px;
  width: auto;
  bottom: auto;
  border-radius: 100%;
  background-color: rgba($color: #000, $alpha: 0.6);
  cursor: pointer;
  transform: translateY(-50%);
}

.carousel-control-prev {
  left: 10px;
}

.carousel-control-next {
  right: 10px;
}

// Optional indicator pips
//
// Add an ordered list with the following class and add a list item for each
// slide your carousel holds.

.carousel-indicators li {
  border-radius: 100%;
}
